.files-view-type-tabs .tab-list{
    border-radius: 62px;
    border: 1px solid rgba(5, 5, 13, 0.30);
    background: rgba(184, 193, 198, 0.50);
    height: 36px;
}
.files-view-type-tabs .tab-list button{
    border-radius: 47px;
    font-family: "Bai Jamjuree";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--dark-color);
}
.files-view-type-tabs .tab-list button[data-state="active"]{
    background: #F2F0FF;
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.10);
}

