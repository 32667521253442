@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

:root {
  --main-color: #75F3FB;
  --dark-color: #05050D;
  --dark-light-color: #242426;
  --dark-hover-color: #1B3039;
  --grey-color: #B8C1C6;
  --grey-hover-color: #B8C1C666;
  --black-color: #000000;
  --white-color: #FFFFFF;
  --blue-color: #75F3FB;
  --yellow-color: #E8FD65;
  --purple-color: #BDB4FD;
  --red-color: #FF5151;
  --green-color: #33FF71;
  --pale-green: #DBFDD8;
  --pale-cyan: #BAFBFF;
  --grey_light-color: #F4F4F4;
  --invalid-input-color: #FF5151;
  --invalid-input-border-color: rgba(255, 81, 81, 0.40);
  --react-international-phone-height: 42px;
  --react-international-phone-font-size: 16px;
}
@font-face {
  font-weight: 800;
  font-family: PP Formula;
  font-display: block;
  src: url("./assets/font/PPFormula-Extrabold.otf") format("opentype");
}
@font-face {
  font-weight: 700;
  font-family: PP Formula;
  font-display: block;
  src: url("./assets/font/PPFormula-SemiExtendedBold.otf") format("opentype");
}
@font-face {
  font-weight: 400;
  font-family: PP Formula;
  font-display: block;
  src: url("./assets/font/PPFormula-Regular.otf") format("opentype");
}

* {
  margin: 0;
  box-sizing: border-box;
}

.italic{
  font-style: italic;
}

body {
  margin: 0;
  padding: 0;
  color: #444;
  font-family: Bai Jamjuree, Onest;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overscroll-behavior: none;
}

body #root *::-webkit-scrollbar {
  width: 0.313rem;
  height: 0.313rem;
}

body #root *::-webkit-scrollbar-track {
  background: rgba(184, 193, 198, 0.2);
  border-radius: 40px;
}

body #root *::-webkit-scrollbar-thumb {
  background-color: rgba(184, 193, 198, 1);
  border-radius: 40px;
}

.button {
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  padding: 10px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  transition: background-color 0.2s ease-in-out 0s;
  white-space: nowrap;
}

.button:disabled {
  opacity: 0.7;
  cursor: default;
}

.button:hover {
  background-color: var(--main-lignt-color);
  border-color: var(--main-lignt-color);
  color: #fff;
}

.button:active {
  box-shadow: 2px 1px 3px rgba(0,0,0, 0.1) inset;
}

input {
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 400;
  width: 100%;
  transition: all 0.2s ease-in-out 0s;
  outline: none;
}

input:hover {
  border-color: var(--main-lignt-color);
}

@media (min-width: 768px) {
  input {
    padding: 20px;
    font-size: 16px;
  }
}

.Main_Title {
  text-align: center;
}

.Button_Container {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  position: relative;
}

.Small_Container {
  width: 320px;
  background-color: #F1F6F6;
  padding: 20px;
  box-shadow: 0 0 3px rgba(0,0,0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}


.Error_Message {
  position: absolute;
  top: 10px;
  text-align: center;
  color: #b65353;
  font-size: 11px;
}

.Success_Message {
  position: absolute;
  top: 10px;
  text-align: center;
  color: #56b653;
  font-size: 11px;
}

#popup-root .popup-overlay{
  background: rgba(5, 5, 13, 0.15);
  backdrop-filter: blur(3px);
}
#popup-root .popup-content{
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.80);
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(20px);
  padding: 20px 20px 30px;
  max-width: 1536px;
  width: calc(100% - 2rem)
}
@media (min-width: 768px) {
  #popup-root .popup-content{
    max-width: 768px;
  }
}
.popup-content .modal .header .close-button{
  cursor: pointer;
}
.popup-content .modal .header .close-button svg{
  transform: rotate(0deg);
  transition: transform 300ms ease-in-out;
}
.popup-content .modal .header .close-button:hover svg{
  transform: rotate(180deg);
}
.popup-content .modal .header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.popup-content .modal .header .title{
  color: var(--dark-color);
  font-family: Bai Jamjuree, Onest;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.popup-content .modal .content{
  margin-top: 10px;
}

.action-button {
  border: none;
  transition: 0.2s;
  opacity: 0.8;
}
.action-button:hover {
  transform: scale(1.05);
  opacity: 1;
}
.action-button:active {
  box-shadow: none;
  transform: scale(0.95);
  opacity: 0.6;
}
.action-button:disabled {
  transform: scale(0.95);
  opacity: 0.4;
}
