.cocustomer-analytics-page-container .custom--dropdown-container .dropdown-tool svg {
    height: 20px;
    width: 20px;
}
.cocustomer-analytics-page-container .custom--dropdown-container .dropdown-input{
    padding: 9px 18px;
}
.cocustomer-analytics-page-container .custom--dropdown-container .dropdown-item:hover,
.cocustomer-analytics-page-container .custom--dropdown-container .dropdown-item.selected{
    background: rgba(5, 5, 13, 0.10);
}
