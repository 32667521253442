.menu-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}
.menu-container .menu-item{
    display: flex;
    flex-direction: row;
    gap: 14px;
    cursor: pointer;
}
@media (max-width: 768px) {
    .menu-container .menu-item{
        gap: 8px;
    }
}

.menu-container .menu-item.disabled{
    cursor: default;
    opacity: 0.4;
}

.general-menu .menu-item .menu-item-icon svg path{
    fill: var(--white-color);
}

.menu-container .menu-item .menu-item-icon svg path{
    opacity: 0.6;
    transition: .2s;
}
.menu-container .menu-item:not(.selected):not(.disabled):hover .menu-item-icon svg path{
    opacity: 1;
    transition: .2s;
}

.menu-container .menu-item .menu-item-add {

}

.menu-container .menu-item > svg path{
    stroke: var(--white-color);
    opacity: 0.6;
}
.menu-container .menu-item.selected{
    background: var(--dark-light-color);
    border-radius: 4px;
}
.menu-container .menu-item.selected .menu-item-icon svg path{
    fill: var(--white-color);
    opacity: 1;
}
.menu-item .menu-item-text{
    flex: 1;
}
.menu-item .menu-item-text p{
    color: rgba(255, 255, 255, 0.60);
    font-family: Bai Jamjuree, Onest;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}
.menu-container .menu-item:not(.selected):not(.disabled):hover p{
    color: var(--white-color);
    opacity: 1;
    transition: 0.2s;
}
.menu-container .menu-item:not(.selected):not(.disabled) p{
    transition: 0.2s;
}
.menu-item.selected .menu-item-text p{
    color: var(--white-color);
    font-weight: 700;
}
.menu-item-container .children-items{
    height: 0;
    overflow: hidden;
    transition: all 200ms;
}
.menu-item-container.expanded > .menu-item > svg{
    transform: rotate(0);
}
.menu-item-container > .menu-item > svg{
    transform: rotate(180deg);
    transition: transform 300ms ease;
}
.menu-item-container.expanded .children-items{
    height: auto;
}

.menu-container .menu-item + .children-items .menu-item.selected{
    background: none;
}
.menu-container .menu-item + .children-items .menu-item .menu-item-text p{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}
.menu-container .menu-item + .children-items .menu-item.selected .menu-item-text p{
    color: var(--white-color);
}
.menu-container .menu-item + .children-items :last-child.menu-item{
    flex-direction: row-reverse;
    gap: 8px;
    justify-content: flex-end;
    align-items: center;
}
.menu-container .menu-item + .children-items :last-child.menu-item .menu-item-wrapper{
    flex-direction: row-reverse;
    border: 1px solid rgba(255, 255, 255, 0.6);
    padding: 8px 18px;
    border-radius: 50px;
}
.menu-container .menu-item + .children-items :last-child.menu-item:hover .menu-item-wrapper,
.menu-container .menu-item + .children-items :last-child.menu-item.selected .menu-item-wrapper {
    border: 1px solid rgba(255, 255, 255, 1);
}
.menu-container .menu-item + .children-items :last-child.menu-item .menu-item-icon{
flex: .5;
}
.menu-container .menu-item + .children-items :last-child.menu-item .menu-item-text{
flex: initial;
justify-content: flex-end;
}
.menu-container .menu-item + .children-items .menu-item:hover .menu-item-icon svg path,
.menu-container .menu-item + .children-items .menu-item.selected .menu-item-icon svg path{
fill: var(--white-color);
opacity: 1;
}

.navigation-menu-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
