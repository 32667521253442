.register-page-container{
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(20px);
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: #F1F6F6;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.register-page-container .register-block{
    border-radius: 10px;
    background: #FFF;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(20px);
    padding: 20px 30px;
    width: 100%;
    max-width: 560px;
}

.mobile .register-page-container .register-block{
    width: 100%;
}

.register-page-container .input-container{
    margin-top: 10px;
    width: 100%;
    position: relative;
}

.register-page-container p {
    color: rgba(5, 5, 13, 0.60);
    font-family: Bai Jamjuree, Onest;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.register-page-container form{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
}
.mobile .register-page-container form{
    min-width: auto;
}

.input-container.buttons{
    flex-direction: row;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 10px;
}
.input-container.buttons button{
    width: 30%;
}
.mobile .input-container.buttons button{
    width: 50%;
}
