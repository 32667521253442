.add-team-member-list{
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.add-team-member-list-item{
    list-style: none;
}
.add-team-member-list-item label{
    display: flex;
    flex-direction: row;
    padding: 15px 20px;
    border-radius: 7px;
    border: 1px solid rgba(184, 193, 198, 0.40);
    background: #FFF;
    gap: 15px;
    align-items: center;
    cursor: pointer;
}
.add-team-member-list-item label:hover{
    border: 1px solid var(--blue-color);
}
.add-team-member-list-item label input{
    width: 16px;
    height: 16px;
    accent-color: var(--dark-color);
}
.add-team-member-list-item label p{
    color: rgba(5, 5, 13, 0.70);
    font-family: Bai Jamjuree, Onest;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
#add-team-member-list-form .input-container.buttons button{
    margin-top: 20px;
    height: auto;
}
