.add-integration-list{
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.add-integration-popup-content{
    max-width: 450px;
}
.add-integration-popup-content .sub-title h4{
    color: rgba(5, 5, 13, 0.50);
    font-family: Bai Jamjuree, Onest;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.add-integration-list{
    padding: 0;
}
.add-integration-list-item{
    list-style: none;
    display: flex;
    flex-direction: row;
    gap: 14px;
    padding: 20px 30px 20px 20px;
    border-radius: 7px;
    border: 1px solid var(--white-color);
    background: #E7F7F7;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}
.add-integration-list-item:hover{
    border: 1px solid var(--blue-color);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.10);
}
.add-integration-list-item > div{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.add-integration-list-item-text{
    color: var(--dark-color);
    font-family: Bai Jamjuree, Onest;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    flex: 1;
}
.add-integration-list-item-add{
    justify-content: flex-end;
    color: #07B5C0;
    text-align: right;
    font-family: Bai Jamjuree, Onest;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    gap: 10px;
}
