.user-avatar{
    width: 28px;
    height: 28px;
    flex-shrink: 0;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-avatar span{
    font-family: Bai Jamjuree, Onest;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}
.user-avatar.active{
    background: #E7F7F7;
}
.user-avatar.active span{
    color: rgba(7, 181, 192, 0.70);
}
.user-avatar.onhold{
    background: rgba(184, 193, 198, 0.20);
}
.user-avatar.onhold span{
    color: rgba(5, 5, 13, 0.70);
}
