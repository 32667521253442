.auth-page-container{
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(20px);
    width: 320px;
    background-color: #F1F6F6;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.auth-page-container p {
    color: rgba(5, 5, 13, 0.70);
    font-family: Bai Jamjuree, Onest;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}
