.custom--multiselect-container {
    text-align: left;
    position: relative;
    cursor: pointer;
    width: 100%;
    max-height: 62px;
}

.custom--multiselect-container .dropdown-input {
    border: 1px solid #B8C1C680;
    background: #ffffff;
    position: relative;
    padding: 10px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    gap: 7px;
    font-weight: 500;
    color: #05050D;
}

@media (min-width: 768px) {
    .custom--multiselect-container .dropdown-input{
        padding: 10px 20px;
    }
}

.custom--multiselect-container .dropdown-input .dropdown-selected-value.placeholder {
    color: #82868b;
}

.custom--multiselect-container .dropdown-tool svg {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.custom--multiselect-container .dropdown-tool svg.translate {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.custom--multiselect-container .dropdown-menu {
    position: absolute;
    border: 1px solid #dbdbdb;
    border-top: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: auto;
    background-color: #fff;
    z-index: 99;
    min-height: 50px;
    width: 100%;
}

.custom--multiselect-container .dropdown-menu::-webkit-scrollbar {
    width: 5px;
}

.custom--multiselect-container .dropdown-menu::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.custom--multiselect-container .dropdown-menu::-webkit-scrollbar-thumb {
    background: #888;
}

.custom--multiselect-container .dropdown-menu::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.custom--multiselect-container .dropdown-menu.alignment--left {
    left: 0;
}

.custom--multiselect-container .dropdown-menu.alignment--right {
    right: 0;
}

.custom--multiselect-container .dropdown-item {
    padding: 0 20px;
    cursor: pointer;
    border: 0;
    transition: 0.2s;
    font-weight: 600;
}
.custom--multiselect-container .dropdown-item label{
    padding: 10px 0;
    width: 100%;
}

.custom--multiselect-container .dropdown-item:disabled {
    cursor: not-allowed;
}

.custom--multiselect-container .dropdown-item:disabled:hover {
    background-color: inherit;
    color: inherit;
}

.custom--multiselect-container .dropdown-item:active {
    box-shadow: none;
}

.custom--multiselect-container .dropdown-item:not(:last-child) {
    border-bottom: 1px solid #B8C1C680;
}

.custom--multiselect-container .dropdown-item:hover {
    background-color: #F8FBFA;
    color: black;
    font-weight: 600;
    transition: 0.2s;
}

.custom--multiselect-container .dropdown-item.selected {
    background-color: #F8FBFA;
    color: black;
    font-weight: 600;
}

.custom--multiselect-container .search-box {
    padding: 5px;
}

.custom--multiselect-container .search-box input {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.custom--multiselect-container .dropdown-tags {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 5px;
}

.custom--multiselect-container .dropdown-tag-item {
    background-color: #ff7300;
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 4px;
    border-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.custom--multiselect-container .dropdown-tag-close {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 5px;
}
