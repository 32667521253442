.sort-files-popup .content > div > .items-center{
  border: 1px solid rgba(184, 193, 198, 0.40);
  border-radius: 5px;
  font-size: 14px;
}
.sort-files-popup .content > div > .items-center button{
  margin-left: 18px;
}
.sort-files-popup .content > div > .items-center label{
  padding: 18px 18px 18px 0;
  width: 100%;
}
