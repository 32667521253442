.checkbox-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
}
.checkbox-container label{
    color: rgba(5, 5, 13, 0.50);
    font-family: Bai Jamjuree, Onest;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.checkbox-input{
    display: none;
}
.checkbox-input+span {
    display: inline-flex;
    align-items: center;
    user-select: none;
}
.checkbox-input + span {
    background:url(./../../assets/img/unchecked.svg) no-repeat;
    background-size: 100%;
    height: 20px;
    width: 20px;
    padding: 0px;
    display:inline-block;
}
.checkbox-input:checked + span {
    background:url(./../../assets/img/checked.svg) no-repeat;
    background-size: 100%;
    height: 20px;
    width: 20px;
    padding: 0px;
    display:inline-block;
}
