
.App_Container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
}

.App_Logo {
    width: 64px;
    height: 64px;
    margin-bottom: 10px;
}
