.Loader {
    width: 100%;
    height: 5px;
    background: var(--main-color);
    border-right: 30vw solid #F8ECF1;
    border-left: 0 solid #F8ECF1;
    animation: loader infinite 1.5s linear;
    border-radius: 5px;
}

@keyframes loader {
    50% {
        border-right: 0 solid #F8ECF1;
        border-left: 30vw solid #F8ECF1;
    }
}
