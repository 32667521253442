.user-settings-button {
  padding: 10px 0;
  color: var(--dark-color);
  text-align: center;
  font-family: "Bai Jamjuree";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.user-settings-button.red {
  color: var(--red-color);
}
.user-settings-button:hover {
  background: rgba(5, 5, 13, 0.1);
}
