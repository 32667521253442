.progress-loader-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(5, 5, 13, 0.15);
    backdrop-filter: blur(3px);
    z-index: 1000;
    display: flex;
    align-items: center;
}
.progress-loader-wrapper .spinner{
    margin: auto;
    top: 50%;
}
.progress-loader-wrapper .progress-loader{
    width: 35%;
    margin: auto;
}
.progress-loader-wrapper .progress-loader > span {
    position: absolute;
    width: 35px;
    top: 0;
    left: calc(50% - 35px/2);
    font-family: Bai Jamjuree, Onest;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}