.accordion-wrapper.expanded .accordion-header .accordion-arrow svg{
    transform: rotate(180deg);
    transition: transform 500ms ease-in-out;
}
.accordion-wrapper.collapsed .accordion-header .accordion-arrow svg{
    transform: rotate(360deg);
    transition: transform 500ms ease-in-out;
}
.accordion-wrapper{
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    border: 1px solid #FFF;
    background-color: #E7F7F7;
    transition: background-color 0.5s ease;
}
.purple.accordion-wrapper{
    background-color: #E7EBFE;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.20);
}
.purple.accordion-wrapper.expanded{
    background-color: #FFFFFF;
}

.purple.accordion-wrapper .accordion-arrow path{
    stroke: #3B3486;
}
.purple.accordion-wrapper.expanded .accordion-arrow path{
    stroke: var(--dark-color);
}
.accordion-wrapper.collapsed:hover{
    border: 1px solid var(--blue-color);
}
.accordion-wrapper.expanded{
    background-color: #FFFFFF;
}
.accordion-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 35px 20px 20px;
    cursor: pointer;
    transition: background 500ms ease;
}
.accordion-arrow {
    display: flex;
    align-items: center;
}
.accordion-header:hover {
    background: #E7F7F7;
}
.accordion-title{
    color: var(--dark-color);
    font-family: Bai Jamjuree, Onest;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.accordion-body{
    padding: 0 35px 20px 20px;
}

@media (hover: none) {
    .purple .accordion-header:hover {
        background: #FFFFFF;
    }
    .purple.collapsed .accordion-header {
        background-color: #E7EBFE;
    }
    .accordion-wrapper.collapsed,
    .accordion-wrapper.collapsed:hover{
        border: none
    }
}