.knowledge-base-move-files-popup .input:not(:read-only):focus {
  border-color: var(--purple-color);
}
.knowledge-base-move-files-popup .filter-list-item label {
  gap: 10px;
  padding: 10px 0;
  border-bottom: 1px transparent solid;
}
.knowledge-base-move-files-popup .filter-list-item label:hover {
  border-bottom: 1px var(--purple-color) solid;
}
.knowledge-base-move-files-popup .filter-list-item p {
  color: rgba(5, 5, 13, 0.7);
  font-family: "Bai Jamjuree";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}
.knowledge-base-move-files-popup .filter-list-item input {
  width: 16px;
  height: 16px;
  accent-color: #05050d;
}
.knowledge-base-move-files-popup #move-to-folder ul {
  height: 50vh;
  max-height: 50vh;
  overflow: auto;
}
.knowledge-base-move-files-popup button {
  height: auto;
  padding: 8px 30px;
}
.knowledge-base-move-files-popup button.purple {
  color: var(--white-color);
}
