.breadcrumbs {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.breadcrumb-item {
    color: var(--dark-color);
    font-family: Bai Jamjuree, Onest;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.breadcrumb-item:after {
    content: '/';
}

.breadcrumbs :last-child.breadcrumb-item {
    font-weight: 700;
}

.breadcrumbs :last-child.breadcrumb-item:after {
    display: none;
}

.breadcrumb-item .badge {
    border-radius: 50px;
    text-align: right;
    font-family: Bai Jamjuree, Onest;
    font-style: normal;
    font-weight: 600;
}

.breadcrumb-item .badge.inwork {
    background: #07B5C0;
    color: var(--dark-color);
}

.breadcrumb-item .onhold {
    background: var(--grey-color);
}