.logout-button{
    color: rgba(255, 255, 255);
    font-family: Bai Jamjuree, Onest;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 14px;
    cursor: pointer;
    transition: .2s;
    opacity: 0.6;
}

.logout-button:hover{
    opacity: 1;
    transition: .2s;
}

@media (max-width: 768px) {
    .logout-button {
        gap: 8px;
    }
}
