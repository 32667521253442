.spinner-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(5, 5, 13, 0.15);
    backdrop-filter: blur(3px);
    z-index: 1000;
}
.spinner-wrapper .spinner{
    margin: auto;
    top: 50%;
}
.spinner {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid #0000;
    /*border-right-color: var(--blue-color);*/
    border-right-color: var(--blue-color);
    position: relative;
    animation: spinner-animation 1s infinite linear;
}
.spinner:before,
.spinner:after {
    content: "";
    position: absolute;
    inset: -8px;
    border-radius: 50%;
    border: inherit;
    animation: inherit;
    animation-duration: 2s;
}
.spinner:after {
    animation-duration: 4s;
}
@keyframes spinner-animation {
    100% {transform: rotate(1turn)}
}
