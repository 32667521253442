.team .empty-message {
    padding: 20px;
    color: rgba(5, 5, 13, 0.70);
    font-family: Bai Jamjuree, Onest;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border-radius: 7px;
    border: 1px solid rgba(184, 193, 198, 0.40);
}

.users-list {
    width: 100%;
    border-collapse: collapse;
    border-radius: 7px;
}

.users-list header {
    display: flex;
    color: var(--grey-color);
    font-family: Bai Jamjuree, Onest;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0 20px;
}

.users-list .row {
    display: flex;
    color: rgba(5, 5, 13, 0.70);
    font-family: Bai Jamjuree, Onest;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 20px;
    border-bottom: 1px solid rgba(184, 193, 198, 0.40);
}

.users-list-body {
    border-radius: 7px;
    border: 1px solid rgba(184, 193, 198, 0.40);
    overflow: hidden;
    margin-top: 7px;
}

.users-list .col {
    flex: 1;
    align-items: center;
    display: flex;
    gap: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.users-list .col-email {
    display: block;
    padding: 0 5px;
}

.users-list .col.name span {
    padding: 0 5px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.users-list .col.edit {
    align-items: center;
    justify-content: center;
}

.users-list .col.flex-3 {
    flex: .3
}

.users-list .col.flex-5 {
    flex: .5;
}

.users-list .col.flex-8 {
    flex: .8
}

.users-list .edit-button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}
.users-list .edit-button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.users-list .edit-button svg path {
    transition: 0.2s;
}

.users-list .edit-button:hover svg path {
    fill-opacity: 1;
    transition: 0.2s;
}
