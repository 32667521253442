.project-page-container{
}
.project-main-container{
    display: flex;
    flex-direction: row;
    gap: 10px
}
.project-main-container .card{
    display: flex;
    flex: 1;
}
.project-page-container .card .card-header .title{
    cursor: default;
}
.project-page-container .card .card-header .title:hover span{
    text-decoration: underline;
}
.card .card-header .title svg,
.project-page-container .card .card-header .title svg{
    transform: rotate(0deg);
    transition: transform 300ms ease-in-out;
}
.card .card-header .title.collapsed svg,
.project-page-container .card .card-header .title.collapsed svg{
    transform: rotate(180deg);
}
.project-main-container .card .card-body{
    display: flex;
    flex-direction: row;
    margin-top: 0;
    gap: 20px;
    align-items: center;
    flex: 1;
}
.project-main-container .card .card-body .text-container{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.project-main-container .card .card-body .text-container p{
    color: rgba(5, 5, 13, 0.40);
    font-family: Bai Jamjuree, Onest;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}
.project-main-container .card .card-body .text-container h4{
    color: var(--dark-color);
    font-family: Bai Jamjuree, Onest;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}
.project-name-container .input-container {
    flex: 1;
}
.project-name-container .card-footer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
}
.project-name-container .card-footer .action-buttons{
    display: flex;
    justify-content: flex-end;
    flex: .5;
    gap: 10px;
}
.project-name-container .card-footer .action-buttons button{
    max-width: 100px;
    max-height: 30px;
    padding: 8px 25px;
    font-size: 12px;
    margin: 0;
    font-weight: 700;
    gap: 10px;
}
.yellow-counter{
    color: var(--dark-color);
    font-family: Bai Jamjuree, Onest;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 3px 9px;
    border-radius: 3px;
    background: #ECF57B;
}

@media (min-width: 768px) {
    .project-main-container .card{
        padding: 40px 20px 40px 40px;
    }
    .project-main-container .card .card-body .text-container h4{
        font-size: 16px;
    }
}

.project-mobile-slider {
    margin-top: -95px;
}

.project-mobile-slider .splide__pagination{
    top: -25px;
    bottom: auto;
    gap: 12px;
}

.project-mobile-slider .splide__pagination .splide__pagination__page:not(.is-active){
    background: #FFFFFF66;
}
