.card{
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.80);
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(20px);
    height: auto;
    transition: height 500ms ease 0ms;
    display: flex;
    flex-direction: column;
}
.card.half-width{
    flex: 50%;
}
.card.disabled,
.card.disabled .react-international-phone-country-selector-button,
.card.disabled .react-international-phone-input{
    opacity: 0.3;
    background: #FFF;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(20px);
    pointer-events: none;
}
.card .card-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 35px;
}
.card .card-header .title{
    color: var(--dark-color);
    font-family: Bai Jamjuree, Onest;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    display: flex;
    align-items: center;
    gap: 7px;
}

.card .card-header .button{
    padding: 8px 17px;
    gap: 7px;
    color: var(--dark-color);
    font-family: Bai Jamjuree, Onest;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-width: 2px;
    margin-top: 0;
    height: auto;
}
.card .card-header .button:hover{
    background: rgba(184, 193, 198, 0.40);
}
.card .card-body{
    margin-top: 20px;
}
.card .card-body form{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}
.card .card-body form .input-container.half-width{
    flex: 48%;
}
.card .card-body form .input-container.full-width{
    flex: 100%;
}
.card .card-footer{
    height: auto;
}
.card .card-footer.hidden{
    height: 0;
    overflow: hidden;
    transition: height 500ms ease 0ms;
}

@media (max-width: 768px) {
    .card.half-width{
        flex: 100%;
    }
    .card .card-header .title{
        font-size: 16px;
    }
    .card .card-body form{
        flex-direction: column;
        width: 100%;
    }
    .card .card-body form .input-container.half-width{
        flex: 100%;
    }
}
