.button{
    border-radius: 500px;
    outline: none;
    cursor: pointer;
    transition: all 0.1s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    width: 100%;
    font-family: Bai Jamjuree, Onest;
    font-size: 15px;
    font-style: normal;
    line-height: normal;
    background: none;
}
.button.transparent {
    border: solid 1px var(--black-color);
    color: var(--dark-color);
}
.button.google:hover{
    box-shadow: none;
    color: var(--dark-color);
    background-color: var(--grey-hover-color);
}
.button.google{
    border: solid 1px var(--black-color);
    color: var(--dark-color);
    background-color: var(--white-color);
    gap: 15px;
}
.button.blue{
    border: none;
    gap: 12px;
    color: var(--blue-color);
    background: var(--dark-color);
}
.button.green{
    border: none;
    gap: 12px;
    color: var(--green-color);
    background: var(--dark-color);
}
.button.purple{
    border: none;
    gap: 12px;
    color: var(--purple-color);
    background: var(--dark-color);
}
.button.red{
    border: none;
    gap: 12px;
    color: var(--white-color);
    background: var(--red-color);
}
.button.red:hover{
    background: #dc4444;
}
.button.purple:hover,
.button.green:hover,
.button.blue:hover{
    background: var(--dark-hover-color);
}
.button.purple:disabled,
.button.green.disabled,
.button.red.disabled,
.button.transparent.disabled,
.button.blue.disabled{
    cursor: default;
    pointer-events: none;
    opacity: .3;
}
.button.transparent.blue{
    color: var(--blue-color);
    background: transparent;
    border: solid 2px var(--blue-color);
}
.button.transparent.blue:hover{
    background: rgba(117, 243, 251, 0.10);
}
.button.transparent.red{
    color: var(--red-color);
    background: transparent;
    border: 2px solid var(--red-color);
}
.button.transparent.red:hover{
    background: #FFF1F1;
}
.button.transparent.black{
    color: var(--dark-color);
    background: transparent;
    border: 2px solid var(--dark-color);
}
.button.transparent.black:hover{
    background: rgba(184, 193, 198, 0.40);
}
