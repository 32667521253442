.Reset_Password__Form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.Reset_Password__Form_Field:last-of-type {
    margin-bottom: 0;
}

.Reset_Password__Loader_Wrapper {
    position: absolute;
    top: 18px;
    width: 100%;
}

