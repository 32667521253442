.add-new-project{
    color: #07B5C0;
    font-family: Bai Jamjuree, Onest;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
}
form#integration-form .integrate-via{
    margin-top: 20px;
}
