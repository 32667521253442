.auth-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 12px;
    margin-bottom: 40px;
}
.auth-header-title{
    color: var(--dark-color);
    font-family: PP Formula;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 800;
    line-height: 165%;
}
