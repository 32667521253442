.project-buttons-mobile{
    right: 26px;
    top: -40px;
    display: flex;
}
.project-buttons-mobile .project-button{
    cursor: pointer;
    user-select: none;
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 19px;
    border-radius: 7px;
    background: rgba(255, 255, 255, 0.90);
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.10);
    color: var(--dark-color);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    opacity: .3;
    transition: opacity 300ms ease;
}
.project-buttons-mobile .project-button:hover{
    opacity: .8;
}
.project-buttons-mobile .project-button.selected{
    opacity: 1;
    cursor: default;
    pointer-events: none;
}

.project-select-filter .footer {
    display: flex;
    flex-direction: row;
}
.project-select-filter .footer > div {
    flex: 1 1;
}

@media (max-width: 992px) {
    .project-buttons-mobile .project-button.selected{
        background: rgba(231,247,247,1);
    }
    .project-buttons-mobile .project-button:hover{
        opacity: .7;
    }
    .project-buttons-mobile .project-button{
        opacity: 1;
        padding: 20px 16px;
    }
}
