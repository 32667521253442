.teammember-popup-content .sub-title{
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.teammember-popup-content h4{
    color: var(--dark-color);
    font-family: Bai Jamjuree, Onest;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.teammember-popup-content .footer {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}
.teammember-popup-content .footer > div{
    flex: 1;
}
.teammember-popup-content .footer .action-buttons{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.teammember-popup-content .footer .action-buttons button{
    height: auto;
    padding: 8px 30px;
}
.input-container.two-columns{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.toggle-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
