.file-list-view {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.file-list-item {
  display: flex;
  padding: 10px 16px 10px 10px;
  align-items: center;
  border-radius: 7px;
  border: 1px solid transparent;
}
.file-list-item.folder {
  background: #e7ebfe;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.file-list-item.file {
  border: 1px solid rgba(184, 193, 198, 0.4);
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #e7ebfe;
}
.file-list-item.checked {
  border: 1px solid rgba(5, 5, 13, 0.3);
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.file-list-item.file.disabled {
  pointer-events: none;
  background: #ccc;
}
.file-list-item.file.disabled .file-list-icon path,
.file-list-item.file.disabled .file-list-icon rect {
  fill: #ccc;
}
.file-list-item.selected {
  border-color: var(--purple-color);
}
.file-list-name {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  gap: 8px;
}
.file-list-text {
  color: rgba(5, 5, 13, 0.7);
  font-family: "Bai Jamjuree";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  flex: 1;
}
.file-list-settings {
  flex: 0.5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.file-list-settings.folder {
  justify-content: end;
}
.file-list-item.file.uploading .file-list-settings,
.file-list-settings.folder {
  justify-content: end;
}
.file-list-settings-date {
  color: rgba(5, 5, 13, 0.5);
  font-family: "Bai Jamjuree";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.file-list-settings-icon svg {
  cursor: pointer;
}
.file-list-settings-icon svg:hover rect {
  stroke: black;
}
.file-list-item .checkbox-container {
  gap: 0;
}
.file-list-item .checkbox-input + span {
  width: 14px;
  height: 14px;
  border: 1px rgba(5, 5, 13, 0.3) solid;
  border-radius: 1px;
  cursor: pointer;
}
.file-list-item .checkbox-input:checked + span {
  border: none;
  width: 14px;
  height: 14px;
  background: url(./../../../assets/img/knowledge-base-checked.svg) no-repeat;
  background-size: 100%;
}
.file-list-view.view-type-grid {
  flex-direction: row;
  flex-wrap: wrap;
  align-content: start;
  justify-content: flex-start;
  gap: 0;
}
.file-list-view.view-type-grid .file-list-item {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 3px;
  height: 130px;
  width: calc(100% / 5 - 6px);
  position: relative;
}
.file-list-view.view-type-grid .file-list-item .file-list-name {
  flex-direction: column;
  align-items: start;
  padding: 30px 15px 15px;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.file-list-view.view-type-grid .file-list-item.folder .file-list-name .file-list-settings {
  flex: 0;
}
.file-list-view.view-type-grid .file-list-item .file-list-name .file-list-settings-icon {
  position: absolute;
  right: 7px;
  top: 8px;
}
.file-list-view.view-type-grid .file-list-item .file-list-name .file-list-checkbox {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.file-list-view.view-type-grid .file-list-item .file-list-name .file-list-text {
  display: flex;
  align-items: flex-end;
  text-overflow: ellipsis;
  /*white-space: nowrap;*/
  width: 100%;
  /*overflow: hidden;*/
}
/*.file-list-view.view-type-grid .file-list-item.folder .file-list-name .file-list-text{*/
/*    align-items: center;*/
/*}*/
.file-list-view.view-type-grid .file-list-item.folder .file-list-name .file-list-icon svg {
  width: 24px;
  height: 20px;
}
.file-list-view.view-type-grid .file-list-item.file .file-list-name .file-list-icon svg {
  width: 20px;
  height: 25px;
}
.file-list-view.view-type-grid .file-list-item .file-list-name .file-list-text span {
  display: flex;
  width: 100%;
  white-space: normal;
  height: 34px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .file-list-item {
    padding: 18px 16px 18px 10px;
  }
  .file-list-view.view-type-grid .file-list-item {
    width: calc(100% / 2 - 6px);
  }
  .file-list-view.view-type-list .file-list-item .file-list-name {
    gap: 10px;
  }
  .file-list-view.view-type-grid .file-list-item .file-list-name {
    align-items: center;
  }
  .file-list-view.view-type-list .file-list-settings {
    justify-content: end;
  }
  .file-list-view.view-type-grid .file-list-item .file-list-name .file-list-text {
    flex-direction: column;
    align-items: start;
    gap: 5px;
    line-height: 16px;
  }
  .file-list-view.view-type-grid .file-list-item .file-list-name .file-list-text span {
    align-items: start;
    justify-content: center;
  }
  .file-list-view.view-type-list .file-list-settings {
    flex: 0.1 1;
  }
}
.list-view-spinner {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid #05050d;
  animation:
    list-view-spinner-1 0.8s infinite linear alternate,
    list-view-spinner-2 1.6s infinite linear;
}
@keyframes list-view-spinner-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }
  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }
  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
  }
}
@keyframes list-view-spinner-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }
  49.99% {
    transform: scaleY(1) rotate(135deg);
  }
  50% {
    transform: scaleY(-1) rotate(0deg);
  }
  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

@media (max-width: 992px) {
  .file-list-view.view-type-list .file-list-settings {
    justify-content: end;
    flex: 0.1 1;
  }
  .file-list-view.view-type-grid .file-list-item .file-list-name .file-list-text {
    flex-direction: column;
    gap: 5px;
    line-height: 16px;
    align-items: start;
  }
  .file-list-view.view-type-list .file-list-settings {
    justify-content: end;
    flex: 0.1 1;
  }
  .file-list-view.view-type-grid .file-list-item .file-list-name .file-list-text {
    flex-direction: column;
    gap: 5px;
    line-height: 16px;
    align-items: start;
  }
  .file-list-view.view-type-grid .file-list-item .file-list-name .file-list-text p {
    text-align: center;
  }
  .file-list-view.view-type-grid .file-list-item .file-list-name .file-list-text svg {
    margin: auto;
  }
}
