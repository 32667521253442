.search-files-container .input-block {
  width: 250px;
}

.search-files-container .input,
.search-files-container .input::placeholder {
  height: 36px;
  font-size: 12px;
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 768px) or (max-width: 992px){
  .search-files-container .input-block{
    width: 100%;
    max-width: 100%;
  }
  .search-files-container .input {
    padding: 9px 30px 9px 17px;
    width: 100%;
  }
}
