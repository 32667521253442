.status-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 50px;
    background: #E7F7F7;
    font-weight: 500;
    color: #07B5C0;
}

.status-wrapper.onhold {
    color: rgba(5, 5, 13, 0.50);
    background: rgba(184, 193, 198, 0.20);
}

@media (max-width: 768px) {
    .status-wrapper svg {
        width: 8px;
        height: 8px;
    }
}
