.sort-files-button{
  border-radius: 4px;
  border: 1px solid rgba(5, 5, 13, 0.30);
  background: rgba(242, 240, 255, 0.50);
  color: var(--dark-color);
  font-family: "Bai Jamjuree";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
