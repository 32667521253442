.Email_Verify {
    width: 100%;
    padding-top: 30px;
    position: relative;
}

.Email_Verify__Loader_Wrapper {
    position: absolute;
    top: 13px;
    width: 100%;
}

.Email_Verify__Result_Text_Container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Email_Verify__Result_Text_Container img {
    height: 20px;
    margin-right: 10px;
}
