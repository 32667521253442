.knowledge-base-page-container{
    height: 100%;
}
.knowledge-base-page-container .card.dashboard .card-body{
    display: flex;
    justify-items: stretch;
}
section.drag-active{
    background-color: grey;
}
.knowledge-base-buttons button{
    padding: 8px 17px;
    font-size: 12px;
    font-weight: 700;
    height: auto;
    margin-top: 0;
}
.knowledge-base-container{
    border-radius: 7px;
    border: 1px solid rgba(184, 193, 198, 0.40);
    padding: 18px 20px;
}
.knowledge-base-buttons .delete-content-button,
.knowledge-base-buttons .move-content-button{
    color: var(--dark-color);
    font-family: "Bai Jamjuree";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-bottom: 1px var(--dark-color) solid;
    opacity: 0.4;
    padding: 6px 0;
    display: flex;
    flex-direction: row;
    gap: 7px;
    cursor: pointer;
}

.knowledge-base-buttons .delete-content-button.active,
.knowledge-base-buttons .move-content-button.active{
    opacity: 1;
}
.knowledge-base-page-container .knowledge-base-delete-files-button,
.knowledge-base-page-container .knowledge-base-move-files-button{
    color: var(--white-color);
    width: auto;
    height: auto;
    gap: 10px;
    padding: 10px 30px;
}
@media (max-width: 768px) or (max-width: 992px) {
    .knowledge-base-container{
        padding: 16px;
    }
    .knowledge-base-buttons{
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    .knowledge-base-buttons > div{
        justify-content: start;
    }
    .knowledge-base-buttons button{
        flex: 0;
        padding: 8px 12px;
        gap: 6px;
        font-size: 12px;
    }
}