.account-page-container{
}
.account-page-container .card-footer .action-buttons{
    display: flex;
    justify-content: flex-end;
    flex: .5;
    gap: 10px;
    margin-top: 20px;
}
.account-page-container .card-footer .action-buttons button{
    max-width: 100px;
    max-height: 30px;
    padding: 8px 25px;
    font-size: 12px;
    margin: 0;
    font-weight: 700;
    gap: 10px;
}
